import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { client } from "../../../lib/clients/apolloClient";
import useAccount from "../../../hooks/useAccount";
import Heading from "../../common/Heading";
import { Link } from "react-router-dom";
import { useGetCognitoAccountQuery } from "../../../generated";
import Sow from "../common/SowRedirect";
import AnswerQuestionnaireIac from "./AnswerQuestionnaireIac";
import ListWorkloadsIac from "./ListWorkloadsIac";
import UploadIacFiles from "./UploadIacFiles";
import CreateIacWorkload from "./CreateIacWorkload";
import AnswerBestPracticeIac from "./AnswerBestPracticeIac";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface WorkloadsDictionary {
  [key: string]: string;
}

interface RiskCountDictionary {
  [key: string]: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Container
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </Container>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function WatIac() {
  const { arn } = useAccount();
  const [value, setValue] = useState(0);
  const [createdWorkloads, setCreatedWorkloads] = useState<WorkloadsDictionary>({});
  const [riskCounts, setRiskCounts] = useState({
    hriCount: 0,
    initialHriCount: 0,
  });
  const [halfStageHriCount, setHalfStageHriCount] = useState<number>(0);
  const [firstRiskCountsDict, setFirstRiskCountsDict] = useState<RiskCountDictionary>({});
  const [riskCountsDict, setRiskCountsDict] = useState<RiskCountDictionary>({});
  const [stateStage, setStateStage] = useState<WorkloadsDictionary>({});
  const [unlockSteps, setUnlockSteps] = useState({
    step2: false,
    step3: false,
    step4: false
  });
  const [process, setProcess] = useState("");
  const [wafrArn, setWafrArn] = useState(arn); //track technical review account that is selected
  const { data: getUserdata } = useGetCognitoAccountQuery({
    variables: {},
    notifyOnNetworkStatusChange: true,
  });

  const handleChange = async (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setValue(newValue);
    //update the queries by refetching it from the server again when you switch tabs
    client.refetchQueries({
      include: "active",
    });
  };

  const updateWorkLoad = async (
    workLoadId: string,
    workLoadName: string,
    highRiskCount: number,
    initialHighRiskCount: number,
  ) => {
    setCreatedWorkloads({[workLoadName]:workLoadId})
    setRiskCountsDict({[workLoadId]:highRiskCount})
    setFirstRiskCountsDict({[workLoadId]:initialHighRiskCount})
    setProcess("UPDATE");
    setUnlockSteps(prevState => ({
      ...prevState,
      step2: true, // Unlock step 2
    }));
    setValue(1);
  };

  //Set Risk Counts for the HRI Counter that is displayed
  useEffect(() => {
    const initialsum = Object.values<number>(firstRiskCountsDict).reduce((acc, value) => acc + value, 0);
    const risksum = Object.values<number>(riskCountsDict).reduce((acc, value) => acc + value, 0);
    setRiskCounts({
      hriCount: risksum,
      initialHriCount: initialsum,
    })
    setHalfStageHriCount(initialsum);
  }, [riskCountsDict, firstRiskCountsDict]); // Add Dicts as a dependencyy
  
  return (
    <Container component="main">
      <Container maxWidth="lg">
        <Heading heading={"AWS Well-Architected Framework Review"} />
        <Typography variant="h4" color="primary.main" paragraph>
          Infrastructure as Code
        </Typography>
      </Container>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider"}}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            variant="fullWidth"
          >
            <Tab label="STEP 1: Define Workload" {...a11yProps(0)} />
            <Tab
              label="STEP 2: Upload Files"
              {...a11yProps(1)}
              disabled={unlockSteps.step2 === false}
            />
            <Tab
              label="STEP 3: Best-Practice Recommendations"
              {...a11yProps(2)}
              disabled={unlockSteps.step3 === false}
            />
            <Tab
              label="STEP 4: People, Policy & Tools Review"
              {...a11yProps(3)}
              disabled={unlockSteps.step4 === false}
            />
            <Tab label="Review Workloads" {...a11yProps(4)} />
            <Tab label="Manual Remediation SoW" 
             component={Link}
             to="/rules?cs=AWS Well Architected Framework&c=FAILED"
             target="_blank"
             rel="noopener noreferrer"
             {...a11yProps(5)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} key={0}>
          <CreateIacWorkload
            organisation={getUserdata?.getCognitoAccount?.organisation}
            setCreatedWorkloads={setCreatedWorkloads}
            wafrArn={wafrArn}
            setWafrArn={setWafrArn}
            setUnlockSteps={setUnlockSteps}
            changeTab={(index: number) => {
              setValue(index);
              setStateStage({});
              setProcess("START");
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <UploadIacFiles 
            createdWorkloads={createdWorkloads}
            wafrArn={wafrArn}
            setRiskCountsDict={setRiskCountsDict}
            setFirstRiskCountsDict={setFirstRiskCountsDict}
            setUnlockSteps={setUnlockSteps}
            changeTab={(index: number) => {
              setValue(index);
              setProcess("START");
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          {/* <AnswerBestPracticeIac
            arn={arn}
            createdWorkloads={createdWorkloads}
            wafrArn={wafrArn}
            riskCounts={riskCounts}
            changeTab={(index: number) => {
              setValue(index);
            }}
            setUnlockSteps={setUnlockSteps}
            process={process}
          ></AnswerBestPracticeIac> */}
        </TabPanel>
        <TabPanel value={value} index={3}>
          <AnswerQuestionnaireIac
            organisation={getUserdata?.getCognitoAccount?.organisation}
            wafrArn={wafrArn}
            createdWorkloads={createdWorkloads}
            riskCounts={riskCounts}
            halfStageHriCount={halfStageHriCount}
            riskCountsDict={riskCountsDict}
            firstRiskCountsDict={firstRiskCountsDict}
            setRiskCountsDict={setRiskCountsDict}
            stateStage={stateStage}
            setStateStage={setStateStage}
            changeTab={(index: number) => {
              setValue(index);
              setCreatedWorkloads({});
              setRiskCountsDict({});
              setFirstRiskCountsDict({});
              setHalfStageHriCount(0);
              setStateStage({});
            }}
            setUnlockSteps={setUnlockSteps}
            process={process}
          ></AnswerQuestionnaireIac>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <ListWorkloadsIac 
            updateWorkLoad={updateWorkLoad}
            setUnlockSteps={setUnlockSteps}
          />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Sow />
        </TabPanel>
      </Box>
    </Container>
  );
}