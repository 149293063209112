import { useState, FC } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Checkbox from "@mui/material/Checkbox";
import CardHeader from "@mui/material/CardHeader";
import {
  CardMedia,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import workloadimage from "./../../../assets/6pillars_wat_graphic_new_color.png";
import { useCreateIacWorkload } from "../../../hooks/useCreateIacWorkload";
import useAccount from "../../../hooks/useAccount";
import LoadingButton from "@mui/lab/LoadingButton";

interface CreateWorkloadProps {
  organisation: any;
  setCreatedWorkloads: any;
  wafrArn: string;
  setWafrArn: (index: string) => void;
  setUnlockSteps: React.Dispatch<React.SetStateAction<{
     step2: boolean; step3: boolean; step4: boolean 
  }>>;
  changeTab: (
    index: number,
  ) => void;
}

interface WorkloadsDictionary {
  [key: string]: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const creditAccountRegExp = /^\d{12}$/;
const technicalReviewAccountRegExp = /^(\d{12})(,\d{12})*$/;

const validationSchema = yup.object({
  // Dynamic validation schema for text fields
  ...new Array(1).fill("").reduce((acc, _, index) => {
    acc[`textField${index + 1}`] = yup
      .string()
      .min(3, "Workload Name should be of minimum 3 characters length.")
      .test(
        "unique",
        "Workload names must be unique",
        function (value, context) {
          const { path, parent } = this;
          const values = Object.values(parent).slice(
            1,
            parent.numberOfWorkloads + 1
          ); // Only consider text fields
          const index = parseInt(String(path.match(/\d+/)?.[0]), 10); // Ensure path is cast to string
          // Ensure values and index are defined
          if (values && !isNaN(index) && values.length > index) {
            const otherValues = values.filter((_, i) => i !== index - 1); // Exclude the current field value from the comparison
            return !otherValues.includes(value);
          }

          return true; // Default to true if unable to perform comparison
        }
      );
    return acc;
  }, {}),
  creditAccount: yup
    .string()
    .matches(
      creditAccountRegExp,
      "Please enter a valid AWS Account Number with no spaces"
    )
    .required("Please enter a valid AWS Account Id"),
  technicalReviewAccount: yup
    .string()
    .matches(
      technicalReviewAccountRegExp,
      "Please enter a valid AWS Account Number with no spaces & add a , after every account number"
    )
    .required("Please enter a valid AWS Account Id"),
});

const CreateIacWorkload: FC<CreateWorkloadProps> = ({
  organisation,
  setCreatedWorkloads,
  wafrArn,
  setWafrArn,
  setUnlockSteps,
  changeTab
}: CreateWorkloadProps) => {
  const { accounts, defaultregion } = useAccount();
  //useCreateWorklod hook
  const { createIacWorkload } = useCreateIacWorkload();

  //Used for the Modal that is displayed after workloads have been set to be created
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [disabled, setDisabled] = useState(false);
  const [complete, setComplete] = useState(false);
  const [activeSpinner, setActiveSpinner] = useState(false); // spinner that displays while workloads are being created
  const [workloadError, setWorkloadError] = useState(false);

  const [statusError, setStatusError] = useState(""); //Display error message from createworkload api
  const [region, setRegion] = useState(defaultregion);

  //Handle that unlocks step 2
  const handleUnlockSteps = () => {
    setUnlockSteps(prevState => ({
      ...prevState,
      step2: true // Unlock step 2
    }));
  };

  //Technical Review Account Dropdown Handle
  const handleWafrAccount = (e: any) => {
    const account = accounts.find((i: any) => i.arn === e.target.value);
    if (account) {
      setWafrArn(account.arn);
      setRegion(account.defaultregion);
    }
    if(e.target.value === "6pl_account"){
      setWafrArn("6pl_account");
      setRegion("ap-southeast-2");
    }
  };

  const addWorkloadKeyValuePairs = (newPairs: WorkloadsDictionary) => {
    setCreatedWorkloads((createdWorkloads: any) => ({
      ...createdWorkloads,
      ...newPairs,
    }));
  };

  const formik = useFormik({
    initialValues: {
      numberOfWorkloads: 1, // Initial value for number of text fields
      // Initial values for dynamic text fields
      ...new Array(1).fill("").reduce((acc, _, index) => {
        acc[`textField${index + 1}`] = "";
        return acc;
      }, {}),
      creditaccount: "",
      technicalReviewAccount: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      //Clear any previous states that might have been passed
      setCreatedWorkloads({});
      //continue with rest of the process
      handleOpen();
      setDisabled(true);
      setActiveSpinner(true);
      setComplete(false);
      let failed = false;
      for (let i = 0; i < values.numberOfWorkloads; i++) {
        let workloadname = organisation + "-" + values[`textField${i + 1}`];
        const response = await Promise.all([
          createIacWorkload(
            workloadname,
            values.creditAccount,
            values.technicalReviewAccount,
            wafrArn,
            region,
          ),
        ]);
        if (
          response[0]?.workloadcreation?.data?.createIacWorkload?.Status !==
          "SUCCESS"
        ) {
          setWorkloadError(true);
          failed = true;
          setStatusError(
            response[0]?.workloadcreation?.data?.createIacWorkload?.Status || ""
          );
        } else {
          setWorkloadError(false);
          addWorkloadKeyValuePairs({
            [workloadname]:
              response[0]?.workloadcreation?.data?.createIacWorkload?.WorkloadId ||
              "",
          });
        }
      }
      setDisabled(false);
      setActiveSpinner(false);
      setComplete(true);
      handleClose();
      if (failed === false) {
        handleUnlockSteps();
        changeTab(1);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Container maxWidth="lg" component={Paper}>
        <Box sx={{ flexGrow: 1, pb: 2 }}>
          <Grid
            container
            sx={{ pb: 2, pt: 2, pl: 2 }}
            spacing={2}
            alignItems="stretch"
            flexDirection={"column"}
          >
            <Typography
              component={"span"}
              variant="h6"
              color="text.primary"
              paragraph
            >
              AUTOMATE+ is integrated with the AWS Well-Architected Framework
              Tool to enable AWS Customers to Auto-Create, Auto-Fill,
              Auto-Remediate, and Auto-Update their Well-Architected Framework
              Review.
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "inherit",
                p: 2,
                pl: 10,
                pr: 10,
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  component={"span"}
                  variant="subtitle1"
                  color="text.secondary"
                  paragraph
                  sx={{ mb: 0 }}
                >
                  STEP 1: Create Workload
                </Typography>
                <Typography
                  component={"span"}
                  variant="subtitle1"
                  color="text.secondary"
                  paragraph
                  sx={{ mb: 0 }}
                >
                  STEP 2: Complete Best-Practice Recommendations
                </Typography>
                <Typography
                  component={"span"}
                  variant="subtitle1"
                  color="text.secondary"
                  paragraph
                  sx={{ mb: 0 }}
                >
                  STEP 3: Complete People, Policy & Tools Review
                </Typography>
              </Box>
              <Box>
                <Button
                  sx={{
                    "&:hover": {
                      color: "primary.main",
                    },
                  }}
                  href="https://aws.amazon.com/architecture/well-architected"
                  target="_blank"
                  variant="outlined"
                >
                  Learn more about AWS Well-Architected Framework
                </Button>
              </Box>
            </Box>
            <Grid item md={10}></Grid>
            <Grid item xs md={12}>
              <Typography
                component={"span"}
                variant="h6"
                color="text.primary"
                paragraph
              >
                Create a Well-Architected Workload in your AWS Account.
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Card raised sx={{ height: "100%" }}>
                <CardHeader
                  action={
                    <CardActions>
                      <Checkbox disabled checked />
                    </CardActions>
                  }
                  title="AWS Well-Architected Framework"
                />
                <CardContent>
                  <Typography sx={{ mb: 1.5 }} color="text.primary">
                    Description
                  </Typography>
                  <Typography variant="body2">
                    The AWS Well-Architected Framework Lens provides a set of
                    foundational questions for you to consider for all of your
                    cloud architectures.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={12}></Grid>
            <Grid item md={12}>
              <Typography variant="body2">
                AWS Well-Architected Framework Reviews can attract AWS credits
                for eligible customers. Please enter the AWS Account Id that will
                receive the credit in the form below.
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Stack direction="row" spacing={2}>
                <TextField
                  sx={{ width: "373.33px", height: "56px" }}
                  id="creditAccount"
                  name="creditAccount"
                  label="AWS Credit Account"
                  variant="outlined"
                  value={formik.values.creditAccount}
                  error={
                      formik.touched.creditAccount &&
                      Boolean(formik.errors.creditAccount)
                  }
                  helperText={
                      formik.touched.creditAccount && 
                      formik.errors.creditAccount
                        ? String(formik.errors.creditAccount)
                        : ""
                  }
                  onChange={formik.handleChange}
                />
              </Stack>
            </Grid>
            <Grid item md={6}></Grid>
            <Grid item md={12}>
              <Typography variant="body2">
                AWS Well-Architected Framework Reviews are conducted on a
                per-workload basis. Please enter the AWS account Ids' below that
                contains the workloads that are to be reviewed.
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Stack direction="row" spacing={2}>
                <TextField
                  sx={{ width: "373.33px", height: "56px" }}
                  id="technicalReviewAccount"
                  name="technicalReviewAccount"
                  label="AWS Technical Review Accounts'"
                  variant="outlined"
                  value={formik.values.technicalReviewAccount}
                  error={
                      formik.touched.technicalReviewAccount &&
                      Boolean(formik.errors.technicalReviewAccount)
                  }
                  helperText={
                      formik.touched.technicalReviewAccount && 
                      formik.errors.technicalReviewAccount
                        ? String(formik.errors.technicalReviewAccount)
                        : ""
                  }
                  onChange={formik.handleChange}
                />
              </Stack>
            </Grid>
            <Grid item md={6}></Grid>
            <Grid item md={12}>
              <Typography variant="body2">
                You have selected a AWS parent account which has multiple
                AWS sub-accounts. Which AWS sub-account contains the
                workload you want to review? Please select from the drop
                down below.
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="aws-credit-account">
                    AWS WAFR Account
                  </InputLabel>
                  <Select
                    required
                    id="awsWafrAccount"
                    name="awsWafrAccount"
                    value={wafrArn}
                    label="AWS Wafr Account"
                    onChange={handleWafrAccount}
                  >
                    <MenuItem
                      key={"six-pillars-account"}
                      value={"6pl_account"}
                    >Create with 6pillars.ai</MenuItem>
                    {accounts.map((a: any) => {
                      let splits = a.arn.split(":");
                      return (
                        <MenuItem
                          key={`${a.accessnickname} - ${splits[4]}`}
                          value={a.arn}
                        >{`${a.accessnickname} - ${splits[4]}`}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={6}></Grid>
            <Grid item md={6}></Grid>
            {[...Array(formik.values.numberOfWorkloads)].map((_, index) => (
              <Grid item md={6} sx={{ pb: 2 }} key={index}>
                <TextField
                  required
                  fullWidth
                  id={`textField${index + 1}`}
                  placeholder={`PROD-Workload Name`}
                  label={`Workload Name`}
                  value={formik.values[`textField${index + 1}`]}
                  error={
                    formik.touched[`textField${index + 1}`] &&
                    Boolean(formik.errors[`textField${index + 1}`])
                  }
                  helperText={
                    formik.touched[`textField${index + 1}`] &&
                    formik.errors[`textField${index + 1}`]
                      ? String(formik.errors[`textField${index + 1}`])
                      : ""
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {organisation}-
                      </InputAdornment>
                    ),
                  }}
                  onChange={formik.handleChange}
                />
              </Grid>
            ))}
            <Grid item md={12}>
              {workloadError === false && complete === true ? (
                <Alert severity="success">Workload successfully created</Alert>
              ) : null}
              {workloadError && complete === true ? (
                <Alert severity="error">{statusError}</Alert>
              ) : null}
            </Grid>
            <Grid item xs md={4} justifyContent="flex-end">
              <LoadingButton
                id="wat-setup-btn"
                variant="contained"
                color="secondary"
                type="submit"
                disabled={disabled}
                loading={activeSpinner}
                sx={{
                  width: 157,
                  height: 40,
                  bgcolor: "secondary.main",
                  "&:hover": {
                    bgcolor: "secondary.main",
                    color: "secondary.contrastText",
                  },
                }}
                loadingIndicator={
                  <CircularProgress color={"secondary"} size={22} />
                }
              >
                Create
              </LoadingButton>
            </Grid>
            <CardMedia
              component="img"
              sx={{ pt: 4 }}
              image={workloadimage}
              alt="6pillars WAT"
            />
          </Grid>
        </Box>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container>
              <Grid item xs md={12}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Please wait
                </Typography>
              </Grid>
              <Grid item xs md={12}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Please wait...Your Well-Architected Framework Review is being
                  created.
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Typically, this should take a couple minutes if its multiple
                  workloads.
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Feel free to press OK however please do not navigate away,
                  close or refresh your browser until you receive confirmation
                  on-screen that your workload has been successfully created as
                  this will impact the workload creation process.
                </Typography>
              </Grid>
              <Grid item xs md={8} sx={{ mt: 2 }}></Grid>
              <Grid item xs md={4} sx={{ mt: 2 }}>
                <Stack sx={{ pt: 4 }} direction="row" spacing={2}>
                  <Button
                    onClick={() => handleClose()}
                    type="submit"
                    variant="contained"
                    sx={{
                      width: 157,
                      height: 40,
                      bgcolor: "secondary.main",
                      "&:hover": {
                        bgcolor: "secondary.main",
                        color: "secondary.contrastText",
                      },
                    }}
                  >
                    Ok
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Container>
    </form>
  );
};

export default CreateIacWorkload;
