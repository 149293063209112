import { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CrossAccountRegion from "./CrossAccountRegion";
import Alert from "@mui/material/Alert";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  SelectChangeEvent,
} from "@mui/material";

interface CrossAccountProps {
  setValue: any;
  setTabSwitch: any;
}
export default function CrossAccount({
  setValue,
  setTabSwitch,
}: CrossAccountProps) {
  const [accountType, setAccountType] = useState("READ_ONLY");
  const [accountCreated, setAccountCreated] = useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    setAccountType(event.target.value as string);
  };

  return (
    <Container>
      <Box sx={{ pt: 4 }}>
        <Container sx={{ width: 1 }} component={Paper}>
          <Grid container spacing={2} alignItems="center">
            <Grid item md={12}>
              <Typography variant="h4" color="primary.main" gutterBottom>
                Deploy AUTOMATE+ to an AWS Account
              </Typography>
            </Grid>
            {accountType !== "" ? (
              <Grid item md={12}>
                <Alert variant="outlined" severity="info">
                  Before you deploy the cross account role, please ensure you
                  are logged into the AWS account that you wish to connect to
                  AUTOMATE+. Deployment type AUTOMATE+ includes all Continuous
                  Compliance features.
                </Alert>
              </Grid>
            ) : null}
            <Grid item md={12}>
              <Typography variant="body2" color="text.primary" paragraph>
                Please select your preferred deployment type:
              </Typography>
              <Typography variant="body2" color="text.primary" paragraph>
                1. AUTOMATE is the Read-Only version without Continuous
                Compliance capabilities.
              </Typography>
              <Typography variant="body2" color="text.primary" paragraph>
                2. AUTOMATE+ Includes all Continuous Compliance features.
              </Typography>
            </Grid>
            <Grid item md={12}>
              <FormControl fullWidth>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Deployment type
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  defaultValue="READ_ONLY"
                  value={accountType}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="READ_ONLY"
                    control={<Radio />}
                    label="AUTOMATE (Read Only: Recommended for New Users)"
                  />
                  <FormControlLabel
                    value="AUTOMATED"
                    control={<Radio />}
                    label="AUTOMATE+ (Full Continuous Compliance Capability: Advanced Users)"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item md={12}></Grid>
          </Grid>
          <CrossAccountRegion
            accountType={accountType}
            setValue={setValue}
            setAccountCreated={setAccountCreated}
            setTabSwitch={setTabSwitch}
          />
        </Container>
      </Box>
    </Container>
  );
}
