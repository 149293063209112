import { FC, useEffect, useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import StyledTableCell from "../../common/StyledTableCell";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import HighRiskNumbers from "../common/HighRiskNumbers";
import MilestoneIacNumber from "./MilestoneIacNumber";
import DeleteIacWorkload from "./DeleteIacWorkload";
import DownloadIacReports from "./DownloadIacReports";
import UpdateIacButton from "./UpdateIacButton";
import { useListIacMilestonesQuery } from "../../../generated";

interface ListWorkLoadItemProps {
  workLoadId: string;
  workLoadName: string;
  updatedAt: string;
  updateWorkLoad: (
    workLoadId: string,
    workLoadName: string,
    highRiskCount: number,
    initialHighRiskCount: number,
  ) => void;
  lenses: string[];
  highRisks: number;
  workLoadlens: string;
  accountIds: string[];
  awsCreditAccount: string;
  awsWafrAccount: string;
}

const ListWorkLoadIacItem: FC<ListWorkLoadItemProps> = ({
  workLoadId,
  workLoadName,
  updatedAt,
  updateWorkLoad,
  lenses,
  highRisks,
  workLoadlens,
  accountIds,
  awsCreditAccount,
  awsWafrAccount,
}: ListWorkLoadItemProps) => {
  const [open, setOpen] = useState(false);
  const [initialHighRiskCount, setInitialHighRiskCount] = useState(0);

  const { data, loading } = useListIacMilestonesQuery({
    variables: {
      workloadid: workLoadId,
      awsWafrAccount: awsWafrAccount,
    },
    context: {
      apiName: "well_architected",
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    const firstHighRiskCount: number = Math.max(
      ...(data?.listIacMilestones?.MilestoneSummaries.map(
        (milestone) => milestone.RiskCounts?.HIGH || 0
      ) || [0, 0])
    );

    setInitialHighRiskCount(firstHighRiskCount);
  }, [data, loading]);

  return (
    <>
      <TableRow>
        <StyledTableCell>{workLoadName}</StyledTableCell>
        <StyledTableCell>
          {new Date(updatedAt).toLocaleString("en-GB")}
        </StyledTableCell>
        <StyledTableCell align="center">
          <MilestoneIacNumber
            awsWafrAccount={awsWafrAccount}
            workloadid={workLoadId}
          />
        </StyledTableCell>
        <StyledTableCell align="center">
          <HighRiskNumbers highrisknumber={highRisks} loading={false} />
        </StyledTableCell>
        <StyledTableCell align="center">
          <UpdateIacButton
            wafrArn={awsWafrAccount}
            workloadid={workLoadId}
            milestonename={workLoadName}
            updateWorkLoad={() => {
              updateWorkLoad(
                workLoadId,
                workLoadName,
                highRisks,
                initialHighRiskCount,
              );
            }}
          />
        </StyledTableCell>
        <StyledTableCell align="center">
          <DownloadIacReports
            wafrArn={awsWafrAccount}
            workloadname={workLoadName}
            workloadid={workLoadId}
            workloadlens={workLoadlens}
          />
        </StyledTableCell>
        <StyledTableCell align="center">
          <DeleteIacWorkload
            awsWafrAccount={awsWafrAccount}
            workloadid={workLoadId}
            workloadname={workLoadName}
          />
        </StyledTableCell>
        <StyledTableCell>
          <IconButton color="secondary" onClick={() => setOpen(!open)}>
            {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
          </IconButton>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, display: "block" }}>
              <Typography variant="body1" align="left" gutterBottom>
                <b>Accounts Assessed:</b> {accountIds.join(", ")}
              </Typography>
              <Typography variant="body1" align="left" gutterBottom>
                <b>Lenses:</b> {lenses}
              </Typography>
              <Typography variant="body1" align="left" gutterBottom>
                <b>AWS Credit Account:</b> {awsCreditAccount}
              </Typography>
              <Typography variant="body1" align="left" gutterBottom>
                <b>AWS WAFR Account:</b> {awsWafrAccount}
              </Typography>
              <Typography variant="body1" align="left" gutterBottom>
                <b>Workload Id:</b> {workLoadId || "N/A"}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ListWorkLoadIacItem;
