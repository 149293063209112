import { useEffect, useState } from "react";
import { useGetTemplateEngineListTemplatesLazyQuery, useGetTemplateEngineSecurityResponseLazyQuery } from "../generated";
import useSnackBars from "./useSnackbar";

interface UploadedFile {
  file: File;
}

// interface Alert {
//   type: 'success' | 'error' | 'warning' | 'info';
//   message: any;
// }

interface UploadTscanProps {
  uploadProgress: number;
  setUploadProgress: (index: number) => void;
  fileUploaded: boolean;
  setFileUploaded: (index: boolean) => void;
  setTscanSummaryIds: React.Dispatch<React.SetStateAction<number[]>>;
}

const useUploadTscan = ({
  uploadProgress, setUploadProgress, fileUploaded, setFileUploaded, setTscanSummaryIds
}: UploadTscanProps) => {
  const [GetTemplateScannerResponse] = useGetTemplateEngineSecurityResponseLazyQuery();
  const [GetListTemplates] = useGetTemplateEngineListTemplatesLazyQuery();
  const { setAlerts } = useSnackBars();

  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [totalUploadedFiles, setTotalUploadedFiles] = useState<UploadedFile[]>([]);

  const checkListTemplatesApi = () => {
    GetListTemplates({
      context: {
        apiName: "template_scanner"
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const resultsJsonString = data?.getTemplateEngineListTemplates?.results;
        if (!resultsJsonString) return;
        
        const resultsJson = JSON.parse(resultsJsonString);
        
        // Map to store the latest summaryId per filename
        const latestFilesMap: { [filename: string]: { summaryId: number, createdAt: Date } } = {};
  
        for (const summaryId in resultsJson) {
          const summaryDict = resultsJson[summaryId].summary;
          
          // Iterate over uploaded files to match filenames
          for (const currentFile of uploadedFiles) {
            const fileName = currentFile.file.name;
  
            // If filename matches, update the map with the latest createdAt
            if (summaryDict.filename === fileName) {
              const createdAt = new Date(summaryDict.created_at.replace(" ", "T"));
  
              // Check if this filename already exists in the map
              if (
                !latestFilesMap[fileName] || 
                createdAt > latestFilesMap[fileName].createdAt
              ) {
                // Update the map with the latest summaryId and createdAt
                latestFilesMap[fileName] = {
                  summaryId: summaryDict.summaryId,
                  createdAt: createdAt,
                };
              }
            }
          }
        }
  
        // Extract the latest summaryIds from the map and update setTscanSummaryIds
        const latestSummaryIds = Object.values(latestFilesMap).map((file) => file.summaryId);
        if (latestSummaryIds.length > 0) {
          
          // Update the state with all latest summaryIds
          setTscanSummaryIds((prevIds) => [...prevIds, ...latestSummaryIds]);
  
          // Set alerts for each file processed
          latestSummaryIds.forEach((id) => {
            setAlerts([{
              severity: 'success',
              msg: `Your uploaded file with summary ID ${id} has finished processing.`,
            }]);
          });
        }
      },
    });
  };
  

  useEffect(() => {
    if (fileUploaded) {
      if (uploadProgress < 100) {
        const timer = setTimeout(() => setUploadProgress(uploadProgress + 10), 30000);
        return () => clearTimeout(timer);
      } else {
        setUploadProgress(0); // Reset progress for the next file
        setFileUploaded(false);
        if (uploadedFiles.length > 0) {
          checkListTemplatesApi();
          setUploadedFiles(prevFiles => prevFiles.slice(1)); // Remove the checked file from the queue
        }
      }
    }
  }, [uploadProgress, uploadedFiles, totalUploadedFiles, fileUploaded]);

  async function uploadTemplateScanner(uploadVariables: any){
    const response = await GetTemplateScannerResponse({
      variables: uploadVariables,
      context: {
          apiName: "template_scanner"
      },
    });
    return response;
  };


  const templateScannerResponse = async (
    files: File[]
  ) => {
    const promises: any[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const result = reader.result ? reader.result as string : "";
        if (result.split("base64,").length > 1) {
          const fileName = file.name;
          const base64Data = result.split("base64,")[1];
          const uploadVariables: any = {
              processType: "upload", 
              fileName: fileName,
              fileString: base64Data,
          }
          const uploadFileResult = await uploadTemplateScanner(uploadVariables);
          promises.push(uploadFileResult);
          setUploadedFiles(prevFiles => [...prevFiles, { file }]);
          setTotalUploadedFiles(prevFiles => [...prevFiles, { file }]);
          if (uploadFileResult?.data?.getTemplateEngineSecurityResponse?.msg === "File uploaded successfully") {
            setAlerts([
                {
                  severity: 'success', 
                  msg: `File ${fileName} uploaded successfully!`
                }
              ]
            );
            setUploadProgress(0);
            setFileUploaded(true);
          }else {
            setAlerts([
                {
                  severity: 'error', 
                  msg: "Error uploading file. " + uploadFileResult?.data?.getTemplateEngineSecurityResponse?.error
                }
              ]
            );
          }
        }
      }
    }
    const response = await Promise.all(promises);
    return { response }
  };
  return { templateScannerResponse, totalUploadedFiles };
};

export { useUploadTscan };